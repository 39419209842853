import { openSuccess } from './modalSuccess';
import { openModal } from './modal';
function form() {
  const forms = document.querySelectorAll('form');

  const message = {
    loading: 'src/img/tube-spinner.svg',
    success: 'Успешно',
    failure: 'Ошибка',
  };

  forms.forEach((item) => {
    bindPostData(item);
  });

  const postData = async (url, data) => {
    let res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: data,
    });

    return await res.json();
  };

  function bindPostData(form) {
    form.addEventListener('submit', (e) => {
      e.preventDefault();
      let tel = form.querySelector('input[name = "phone"]');

      let result = parseInt(tel.value.replace(/[^\d]/g, '')).toString();

      if (result.length != 12) {
        return;
      }
      function formValidate(form) {
        let error = 0;
        let formReq = form.querySelectorAll('._req');

        for (let index = 0; index < formReq.length; index++) {}
      }

      formValidate(form);

      let statusMessage = document.createElement('img');
      statusMessage.src = message.loading;
      statusMessage.classList.add('spiner');

      form.insertAdjacentElement('afterend', statusMessage);

      const formData = new FormData(form);

      const json = JSON.stringify(Object.fromEntries(formData.entries()));

      postData('request.php', json)
        .then((data) => {
          openModal('.modal');
          openSuccess();
        })
        .catch(() => {
          openModal('.modal');
          openSuccess();
          statusMessage.failure;
        })
        .finally(() => {
          statusMessage.remove();
          form.reset();
        });
    });
  }
}

export default form;
