import { closeSuccess } from './modalSuccess';
function closeModal(modalSelector) {
  const modal = document.querySelector(modalSelector);

  modal.classList.add('modal_hide');
  modal.classList.remove('modal__show');
  closeSuccess();
  document.body.style.overflow = '';
}

function openModal(modalSelector) {
  const modal = document.querySelector(modalSelector);

  modal.classList.add('modal__show');
  modal.classList.remove('modal_hide');
  document.body.style.overflow = 'hidden';
}

function modal(triggerSelector, modalSelector) {
  const modalTrigger = document.querySelector(triggerSelector),
    modal = document.querySelector(modalSelector);

  modalTrigger.addEventListener('click', () => openModal(modalSelector));
  modal.addEventListener('click', (e) => {
    if (e.target === modal || e.target.getAttribute('data-close') == '') {
      closeModal(modalSelector);
    }
  });

  document.addEventListener('keydown', (e) => {
    if (e.code === 'Escape' && modal.classList.contains('modal__show')) {
      closeModal(modalSelector);
    }
  });
}

export default modal;
export { closeModal };
export { openModal };
