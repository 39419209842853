const accordion = () => {
  const futureLinkElems = document.querySelectorAll('.question__accordion_img');
  const futureSubElems = document.querySelectorAll('.question__accordion_text');

  futureLinkElems.forEach((elem, index) => {
    elem.addEventListener('click', () => {
      if (elem.classList.contains('question_select')) {
        elem.classList.remove('question_select');
        futureSubElems[index].classList.add('accordion-hidden');
      } else {
        futureLinkElems.forEach((futureLinkElem) => {
          futureLinkElem.classList.remove('question_select');
        });

        futureSubElems.forEach((futureSubElem) => {
          futureSubElem.classList.add('accordion-hidden');
        });

        futureSubElems[index].classList.remove('accordion-hidden');
        elem.classList.add('question_select');
      }
    });
  });
};

export default accordion;
