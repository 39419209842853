function openSuccess() {
  const success = document.querySelector('.modal__success');
  const formDialog = document.querySelector('.modal__dialog');

  formDialog.classList.add('modal_hide');
  success.classList.remove('modal_hide');
}

function closeSuccess() {
  const success = document.querySelector('.modal__success');
  const formDialog = document.querySelector('.modal__dialog');

  formDialog.classList.remove('modal_hide');
  success.classList.add('modal_hide');
}

export { openSuccess };
export { closeSuccess };
