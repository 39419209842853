const humburger = () => {
  const hamburger = document.querySelector('.hamburger');
  const closeNav = document.querySelector('.navigation__close');
  const navigation = document.querySelector('.navigation');

  hamburger.addEventListener('click', () => {
    hamburger.classList.add('_not-active');
    navigation.classList.add('navigation__active');
  });

  closeNav.addEventListener('click', () => {
    hamburger.classList.remove('_not-active');
    navigation.classList.remove('navigation__active');
  });
};

export default humburger;
