const swiper = new Swiper('.review__slider', {
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
    dynamicBullets: true,
  },
  breakpoints: {
    // условия для разных размеров окна браузера
    // 0: { // при 0px и выше
    // 	direction: 'horizontal', // горизонтальная прокрутка
    // },
    768: {
      // при 768px и выше
      slidesPerView: 2,
    },
    992: {
      // при 768px и выше
      slidesPerView: 3,
    },
    1200: {
      slidesPerView: 4,
    },
  },
});

// const swiperInstalation = new Swiper('.swiper-instalation', {
//   pagination: {
//     el: '.swiper-pagination',
//     clickable: true,
//     dynamicBullets: true,
//   },
//   breakpoints: {
//     // условия для разных размеров окна браузера
//     // 0: { // при 0px и выше
//     // 	direction: 'horizontal', // горизонтальная прокрутка
//     // },
//     768: {
//       // при 768px и выше
//       slidesPerView: 2,
//     },
//     992: {
//       // при 768px и выше
//       slidesPerView: 3,
//     },
//     1200: {
//       // при 768px и выше
//       slidesPerView: 3,
//     },
//   },
// });

export default swiper;
// export { swiperInstalation };
