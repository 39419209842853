import hamburger from './modules/hamburger';
import mask from './modules/mask';
import form from './modules/form';
import modal from './modules/modal';
import accordion from './modules/accordion';
import smooth from './modules/smooth';
import swiper from './modules/slider';
import video from './modules/video';

hamburger();
mask();
form();
modal('[data-modal]', '.modal');
accordion();
smooth();
swiper;
video();
// const player = new Player('.j-player');
